
import CircleSpinnerToCheckmark from "@/components/spinners/CircleSpinnerToCheckmark.vue";
import FadeSpinner from "@/components/spinners/FadeSpinner.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Alert, ContentNode } from "@/types/layout/contentNode";
import SimpleIconTitle from "@/components/common/SimpleIconTitle.vue";
import SimpleAlert from "@/components/common/SimpleAlert.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import { MarketplaceRegistrationRequest } from "@jfrog-ba/myjfrog-common";
declare var PLATFORM_DOMAIN: any;

@Component({
  name: "RegistrationActivationFlow",
  components: {
    CircleSpinnerToCheckmark,
    FadeSpinner,
    SimpleIconTitle,
    SimpleAlert,
    ProgressBar,
  },
})
export default class RegistrationActivationFlow extends Vue {
  @Prop() isReady = false;
  @Prop() isDelayed = false;
  @Prop() waitingRoomData!: MarketplaceRegistrationRequest;
  progressBarPercent: number = 0;
  progressBarMessage = "This typically takes less than one minute.";
  minSecondsBeforeRedirect: number = 60;
  delaySeconds: number = this.minSecondsBeforeRedirect / 100;
  storageKey = "mpActivation";
  serverData: Array<ContentNode> = [];

  alertData: Alert = {
    message: "Activation is taking longer than expected, an email will be sent once the environment is ready.",
    type: "warn",
  };

  ePlusNotifyData: Alert = {
    message: `When you log in, you may see some services disabled. Once we're done<br/> setting up, you'll see those services as well.`,
    type: "warn",
  };

  mounted() {
    this.serverData = [
      {
        title: "Server Name",
        value: this.waitingRoomData.serverName,
        icon: "activation_server_mp.svg",
      },
      {
        title: "Location",
        value: this.waitingRoomData.cloudProvider + " in " + this.waitingRoomData.regionName,
        icon: "activation_location_mp.svg",
      },
    ];
    this.setProgressBarInitialValue();
    this.increaseProgressBarPercent();
  }

  setProgressBarInitialValue() {
    let progressBarInitial = sessionStorage.getItem(this.storageKey);
    if (progressBarInitial && parseInt(progressBarInitial) > 0) {
      this.progressBarPercent = parseInt(progressBarInitial);
    } else {
      this.progressBarPercent = 0;
    }
  }

  get titleText() {
    return this.waitingRoomData.isTrialEnterprisePlus
      ? "Your DevOps Journey Is About To Begin!"
      : "Ready for your DevOps journey?";
  }

  get subtitleText() {
    return this.waitingRoomData.isTrialEnterprisePlus
      ? "Your JFrog Enterprise+ Trial environment is being created"
      : "Please hang on while we create your environment";
  }

  get showAlert() {
    return this.isDelayed || (this.progressBarPercent > 89 && !this.isReady);
  }

  get showEPlusNotifyData() {
    return this.waitingRoomData.isEnterprisePlus;
  }

  goToPlatform() {
    let url = this.waitingRoomData.instanceUrl || `https://${this.waitingRoomData.serverName}.${PLATFORM_DOMAIN}/`;
    if (url.indexOf("?") > -1) {
      url += "&firstRedirect=true";
    } else {
      url += "?firstRedirect=true";
    }
    window.location.href = url;
  }

  increaseProgressBarPercent() {
    if (this.progressBarPercent > 99 && this.isReady) {
      sessionStorage.removeItem("registrationFormData");

      this.goToPlatform();
    }
    if (this.progressBarPercent < 90 && !this.isDelayed) {
      this.progressBarPercent++;
    }
    if (this.isReady) {
      this.progressBarPercent += 20;
    }
    if (this.progressBarPercent > 100) {
      this.progressBarPercent = 100;
    }
    sessionStorage.setItem(this.storageKey, this.progressBarPercent.toString());
    setTimeout(() => {
      this.increaseProgressBarPercent();
    }, this.delaySeconds * 1000);
  }
}
