import { render, staticRenderFns } from "./RegistrationActivationFlow.vue?vue&type=template&id=309544bc&scoped=true&"
import script from "./RegistrationActivationFlow.vue?vue&type=script&lang=ts&"
export * from "./RegistrationActivationFlow.vue?vue&type=script&lang=ts&"
import style0 from "./RegistrationActivationFlow.vue?vue&type=style&index=0&id=309544bc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "309544bc",
  null
  
)

export default component.exports